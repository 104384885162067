import React from "react";

import InfoModal from "../modals/InfoModal";

function Header() {
  return (
    <header className="flex w-full justify-between border-b px-6 py-2">
      <h1 className="font-space-mono">Connections Game Today</h1>
      <InfoModal />
    </header>
  );
}

export default Header;
